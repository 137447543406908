import { NextLayoutComponentType } from 'next';
import withGuestRequired from '@components/HOC/withGuestRequired';
import AuthLayout from '@layouts/AuthLayout';
import AuthLogin from '@components/pages/Auth/AuthLogin';

const Home: NextLayoutComponentType = () => {
  return (
    <>
      <div className="flex-grow flex items-center">
        <AuthLogin />
      </div>
    </>
  );
};

Home.getLayout = (page) => <AuthLayout>{page}</AuthLayout>;

export default withGuestRequired(Home, '/dashboard');
