import { ReactNode } from 'react';
import Head from 'next/head';

export interface LayoutProps {
  title?: string;
  children?: ReactNode | ReactNode[];
}

const BaseLayout = ({ title, children }: LayoutProps) => {
  return (
    <>
      <Head>
        <title>Simple Around{title ? ` | ${title}` : ''}</title>
      </Head>
      {children}
    </>
  );
};

export default BaseLayout;
