import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { ReactHookFormDevTools } from '@simplecy/frontend-types/components/core/DevTools/ReactHookFormDevTools';
import * as React from 'react';
import { Button, Input } from '@simplecy/frontend-ui';
import InputPassword from '@components/core/InputPassword';
import ky from '@utils/ky';
import useAuth from '@hooks/useAuth';
import { useRouter } from 'next/router';
import { useWait } from '@simplecy/frontend-ui/hooks';

const AuthLogin = () => {
  const router = useRouter();
  const { update: updateAuthToken } = useAuth();

  const { register, control, handleSubmit, formState } = useForm<any>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(
      yup.object({
        email: yup.string().required().email(),
        password: yup.string().required(),
      }),
    ),
  });
  const { errors, touchedFields } = useMemo(() => formState, [formState]);

  const { startWaiting, endWaiting, isWaiting } = useWait();

  const onSubmit = useCallback(async (event) => {
    try {
      const { email, password } = event;
      startWaiting('Auth.Login');
      const response = await ky
        .post('auth/admin-login', {
          json: {
            email,
            password,
          },
        })
        .json();
      await updateAuthToken(response);
      const target = (() => {
        try {
          return (
            window.decodeURIComponent(window.atob(String(router.query.u))) ??
            '/dashboard'
          );
        } catch (e) {
          return '/dashboard';
        }
      })();
      await router.push(target);
    } catch (e) {
    } finally {
      endWaiting('Auth.Login');
    }
  }, []);
  const onError = useCallback(() => {}, []);

  return (
    <>
      {process.env.NODE_ENV === 'development' && (
        <ReactHookFormDevTools
          placement="top-left"
          control={control}
        />
      )}

      <form
        className="flex-grow flex flex-col space-y-10"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="flex-grow flex flex-col lg:justify-center">
          <div className="flex flex-col space-y-10">
            <div className="flex flex-col space-y-[10px]">
              <p className="text-[24px] font-semibold leading-[30px]">
                Sign in
              </p>
              <span className="text-[#8E8E9F] leading-[20px]">
                Discover and get discovered with Simple Around
              </span>
            </div>
            <div className="flex flex-col space-y-[22px]">
              <div className="flex flex-col space-y-[12px]">
                <Input
                  placeholder="Enter your email address"
                  endAdornment={
                    <div className="w-[24px] h-[24px] flex items-center justify-center">
                      <svg
                        width="17"
                        height="13"
                        viewBox="0 0 17 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 0C0.895431 0 0 0.89543 0 2V11C0 12.1046 0.89543 13 2 13H15C16.1046 13 17 12.1046 17 11V2C17 0.895431 16.1046 0 15 0H2ZM2.42352 2.12692L1.82651 2.87309L8.33655 8.28804L14.8466 2.87309L14.2496 2.12692L8.33655 7.0453L2.42352 2.12692ZM1.76761 10.0186L4.87338 6.35196L5.58815 6.98137L2.48239 10.648L1.76761 10.0186ZM12.1266 6.35196L15.2324 10.0186L14.5176 10.648L11.4118 6.98137L12.1266 6.35196Z"
                          fill="#8E8E9F"
                        />
                      </svg>
                    </div>
                  }
                  error={!!errors?.email}
                  errorEndAdornment
                  {...register('email')}
                />
                <InputPassword
                  placeholder="Enter your password"
                  error={!!errors?.password}
                  errorEndAdornment
                  {...register('password')}
                />
              </div>
              <Button
                bg="#FFA269"
                hoverBg="#F97E35"
                border="#FFA269"
                hoverBorder="#F97E35"
                color="#FFFFFF"
                hoverColor="#FFFFFF"
                type="submit"
                busy={isWaiting('Auth.Login')}
                disabled={isWaiting('Auth.Login')}
              >
                <span>Continue</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AuthLogin;
