import Div100vh from 'react-div-100vh';
import BaseLayout, { LayoutProps } from '@layouts/BaseLayout';
import { ReactNode } from 'react';
import { SimpleLogo } from '@simplecy/frontend-ui';

interface AuthLayoutProps extends LayoutProps {
  wrapperClassName?: string;
  pane?: ReactNode;
}

const AuthLayout = ({
  title,
  children,
  wrapperClassName = 'flex-grow flex',
  pane,
}: AuthLayoutProps) => {
  return (
    <>
      <BaseLayout title={title}>
        <Div100vh className="overflow-y-auto flex flex-col bg-white lg:bg-[#F7F7F7]">
          <div className="container mx-auto bg-white">
            <div className={wrapperClassName}>
              <div className="max-w-full flex-grow flex flex-col px-4 flex-shrink-0">
                <div className="flex-grow flex flex-col py-14 lg:py-20">
                  <div className="flex-grow w-full max-w-full lg:max-w-[448px] md:min-w-[448px] mx-auto flex flex-col space-y-10">
                    <div className="flex-shrink-0 flex items-center space-x-4 justify-between min-h-[50px]">
                      <SimpleLogo />
                    </div>
                    <div className="flex-grow flex flex-col">{children}</div>
                  </div>
                </div>
              </div>
              {pane || (
                <Div100vh className="hidden lg:flex w-[422px] xl:w-[634px] bg-[#F7F7F7] rounded-l-[30px] sticky top-0 overflow-hidden">
                  <video
                    className="w-full h-full object-cover object-center"
                    autoPlay
                    loop
                    muted
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <source src="/video/pexels-6981328-@1920x1080.mp4" />
                  </video>
                </Div100vh>
              )}
            </div>
          </div>
        </Div100vh>
      </BaseLayout>
    </>
  );
};

export default AuthLayout;
